import { ChangeDetectorRef, Component, Inject, AfterViewInit, ViewChild, AfterViewChecked, ViewContainerRef } from '@angular/core';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { ComputorFactory } from '../../../../computors/computor.factory';

@Component({
    selector: 'app-project-show',
    templateUrl: './report-show-step.component.html',
    styleUrls: ['./report-show-step.component.scss']
})
export class ReportShowStepComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('computorComponentRef', {
        read: ViewContainerRef
    })
    public viewContainerRef: ViewContainerRef;

    public folderProject: FolderProjectResource = null;

    constructor(
        private pageTitleService: PageTitleService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(ComputorFactory) private factory: ComputorFactory,
    ) {
        this.folderProject = this.activatedRoute.snapshot.data.folder as FolderProjectResource;
    }

    public ngAfterViewInit(): void {
        this.factory.loadComputorComponent(this.viewContainerRef, this.folderProject.data.tenderModel.computerName, 'ReportShowComponent');
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }
}
