import { ChangeDetectorRef, Component, Inject, AfterViewInit, ViewChild, AfterViewChecked, ViewContainerRef } from '@angular/core';
import { TenderSession } from '../../../../core/models/tender-session.model';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { ComputorFactory } from '../../../../computors/computor.factory';

@Component({
    selector: 'app-admin-report-validation2',
    templateUrl: './report-validation2.component.html',
    styleUrls: ['./report-validation2.component.scss']
})
export class ReportValidation2Component implements AfterViewInit, AfterViewChecked {
    @ViewChild('computorComponentRef', {
        read: ViewContainerRef
    })
    public viewContainerRef: ViewContainerRef;

    public tenderSession: TenderSession;
    public folderProject: FolderProjectResource = null;

    constructor(
        private pageTitleService: PageTitleService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(ComputorFactory) private factory: ComputorFactory,
    ) {
        this.folderProject = this.activatedRoute.snapshot.data.folder as FolderProjectResource;
        this.tenderSession = this.folderProject.data.tenderSession;
    }

    public ngAfterViewInit(): void {
        const component =
            this.factory.loadComputorComponent(this.viewContainerRef, this.folderProject.data.tenderModel.computerName, 'AdminReportValidation2Component');
        component.setTenderSession(this.tenderSession);
        component.setFolderProject(this.folderProject);
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }
}
